<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="send-notification-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base flex items-center gap-2">
            <app-i18n code="notification.new.title"></app-i18n>
            <div class="text-theme-31">
              <app-i18n code="common.to" class="text-black"></app-i18n>
            </div>
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
          <div class="col-span-12">
            <label for="modal-form-3" class="form-label">{{
              i18n('notification.fields.image')
            }}</label>
            <Dropzone
              ref-key="dropzoneRef"
              id="dropzoneRef"
              :options="{
                url: 'https://httpbin.org/post',
                paramName: 'file',
                thumbnailWidth: 150,
                maxFilesize: 2,
                // clickable: !isMaxUploaded,
                addRemoveLinks: true,
                maxFiles: 1,
                headers: { 'My-Awesome-Header': 'header value' }
              }"
              class="dropzone"
            >
              <div class="text-lg font-medium">
                {{ i18n('settings.shippingTab.modal.fileUploaderHint') }}
              </div>
            </Dropzone>
          </div>
          <div class="col-span-12">
            <label for="modal-form-3" class="form-label">{{
              i18n('notification.fields.title')
            }}</label>
            <input
              id="title-en"
              type="text"
              class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
              v-model="model.title.en"
              :placeholder="i18n('notification.fields.english')"
            />
            <input
              id="title-ar"
              type="text"
              class="form-control w-full dark:bg-dark-9 dark:placeholder-white mt-2"
              v-model="model.title.ar"
              :placeholder="i18n('notification.fields.arabic')"
            />
          </div>
          <div class="col-span-12">
            <label for="modal-form-3" class="form-label">{{
              i18n('notification.fields.title')
            }}</label>
            <input
              id="title-en"
              type="text"
              class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
              v-model="model.title.en"
              :placeholder="i18n('notification.fields.english')"
            />
            <input
              id="title-ar"
              type="text"
              class="form-control w-full dark:bg-dark-9 dark:placeholder-white mt-2"
              v-model="model.title.ar"
              :placeholder="i18n('notification.fields.arabic')"
            />
          </div>
          <div class="col-span-12">
            <label for="modal-form-3" class="form-label">{{
              i18n('notification.fields.body')
            }}</label>
            <textarea
              id="body-en"
              :rows="4"
              class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
              v-model="model.body.en"
              :placeholder="i18n('notification.fields.english')"
            ></textarea>
            <textarea
              id="body-ar"
              :rows="4"
              class="form-control w-full dark:bg-dark-9 dark:placeholder-white mt-2"
              v-model="model.body.ar"
              :placeholder="i18n('notification.fields.arabic')"
            ></textarea>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-center items-center">
          <AppButton
            type="button"
            class="btn bg-theme-1 text-white cursor-pointer"
            :class="!saveLoading ? 'w-24' : ''"
            :disabled="saveLoading || uploadLoading"
            :loading="saveLoading"
            data-dismiss="modal"
            @click="doSubmit()"
          >
            <strong>{{ i18n('common.send') }}</strong>
            <template v-slot:loading>
              <app-i18n code="common.loading"></app-i18n>
              <LoadingIcon
                icon="three-dots"
                color="#FFFFFF"
                style="margin: 0 4px"
              />
            </template>
          </AppButton>
          <button
            type="button"
            data-dismiss="modal"
            class="btn bg-theme-31 text-white w-24 ml-3 mr-3 cursor-pointer"
            :disabled="saveLoading || uploadLoading"
            @click="doReset"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, ref, onMounted, provide } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Dropzone from 'dropzone'
// import Message from '@/shared/message/toastify'

export default defineComponent({
  props: {
    userIds: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: false
    }
  },
  setup() {
    const hideModal = () => {
      cash('#send-notification-modal').modal('hide')
    }
    let uploadLoading = ref(false)
    const dropzoneRef = ref()
    const filesToSave = ref([])
    provide('bind[dropzoneRef]', (el) => {
      dropzoneRef.value = el
    })
    const clearDropzone = () => {
      Dropzone.forElement('#dropzoneRef').removeAllFiles(true)
      while (filesToSave.value.length > 0) {
        filesToSave.value.pop()
      }
    }
    const getDropZoneInstance = () => {
      return dropzoneRef.value
    }
    const setupDropZone = () => {
      const elDropzoneRef = getDropZoneInstance()
      elDropzoneRef.dropzone.on('success', (file) => {
        filesToSave.value.push(file)
      })

      elDropzoneRef.dropzone.on('removedfile', (file) => {
        filesToSave.value.pop()
      })

      elDropzoneRef.dropzone.on('complete', () => {
        uploadLoading = false
      })

      elDropzoneRef.dropzone.on('uploadprogress', () => {
        uploadLoading = true
      })
    }
    onMounted(() => {
      setupDropZone()
    })
    return {
      setupDropZone,
      uploadLoading,
      filesToSave,
      getDropZoneInstance,
      clearDropzone,
      hideModal
    }
  },
  data() {
    return {
      model: {
        title: {
          en: '',
          ar: ''
        },
        body: {
          en: '',
          ar: ''
        }
      },
      errorMessage: 'Please fill all the fields'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'notification/form/record',
      saveLoading: 'notification/form/saveLoading'
    })
  },
  async mounted() {
    this.doNew()
  },
  methods: {
    ...mapActions({
      doNew: 'notification/form/doNew',
      sendToCustom: 'notification/form/sendToCustom',
      sendToAll: 'notification/form/sendToAll'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'notification.create.popup.emptyFields'
            return false
          }
        }
      }
      return true
    },
    doReset() {
      this.clearDropzone()
      this.hideModal()
      // this.$emit('close')
    },
    async doSubmit() {
      const { ...values } = this.model

      if (this.$props.userIds.length > 0) {
        const userIds = this.$props.userIds
        await this.sendToCustom({
          notification: values,
          image: this.filesToSave.length ? this.filesToSave[0] : '',
          userIds
        })
      } else {
        await this.sendToAll({
          notification: values,
          image: this.filesToSave.length ? this.filesToSave[0] : ''
        })
      }
      this.doReset()
    }
  }
})
</script>

<style lang="scss">
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
