<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('user.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    >
    </TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      ></div>
      <div class="flex gap-5 justify-end mb-[15px]">
        <button
          class="btn bg-theme-31 text-white h-10"
          :disabled="this.selected.length === 0"
          @click="notificationType = 'all'"
        >
          <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#send-notification-modal"
          >
            <SendIcon class="w-4 h-4" />
            {{ i18n('common.notifySelectedUsers') }}
          </a>
        </button>
        <button
          class="btn bg-theme-31 text-white h-10"
          @click="notificationType = 'custom'"
        >
          <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#send-notification-modal"
          >
            <SendIcon class="w-4 h-4" /> {{ i18n('common.sendNotification') }}
          </a>
        </button>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="controller"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <table
            class="table table-report sm:mt-2"
            id="adminTable"
            ref="tableRef"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap" id="">#</th>
                <th class="whitespace-nowrap text-center" id="ignore-1">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="selectAll"
                    @click="select"
                  />
                </th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="
                    col.name === 'action'
                      ? 'ignore-2'
                      : col.name === 'avatar'
                      ? 'ignore-3'
                      : ''
                  "
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr v-for="(row, index) in rows" :key="index">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <td id="ignore-4">
                  <div class="text-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="row.id"
                      v-model="selected"
                    />
                  </div>
                </td>
                <td
                  class="text-center flex items-center justify-center"
                  id="ignore-5"
                >
                  <app-list-item-image
                    :value="row.avatar?.publicUrl"
                    type="user"
                  ></app-list-item-image>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'fullName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'email') }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="font-medium whitespace-nowrap"
                    style="direction: ltr !important"
                  >
                    {{ presenter(row, 'phoneNumber') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'birthDate') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="w-40">
                  <div
                    class="flex items-center justify-center whitespace-nowrap"
                    :class="{
                      'text-theme-24': row.disabled,
                      'text-green-600 dark:text-theme-30': !row.disabled
                    }"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                    {{
                      !row.disabled
                        ? i18n('common.activated')
                        : i18n('common.disabled')
                    }}
                  </div>
                </td>
                <td class="table-report__action w-56" id="ignore-6">
                  <div
                    class="flex justify-center items-center gap-3 text-theme-24"
                  >
                    <router-link
                      class="text-theme-10 dark:text-theme-30 flex items-center gap-2 whitespace-nowrap"
                      :to="`/users/${row.id}`"
                    >
                      <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                    </router-link>
                    <button
                      v-if="row.disabled && hasPermissionToChangeStatus"
                      @click="toggleStatus(row.id, false)"
                      class="flex justify-center items-center gap-2 p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md text-green dark:text-theme-30"
                      style="color: green"
                    >
                      <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                      {{ i18n('common.activate') }}
                    </button>
                    <button
                      v-if="!row.disabled && hasPermissionToChangeStatus"
                      @click="toggleStatus(row.id, true)"
                      class="flex justify-center items-center gap-2 p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md text-theme-24 dark:text-theme-24"
                    >
                      <LockIcon class="w-4 h-4 mr-1 ml-1" />
                      {{ i18n('common.disable') }}
                    </button>
                    <a
                      v-if="hasPermissionToDestroy"
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#delete-modal-preview"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      @click="selectedId = row.id"
                      ><Trash2Icon class="w-4 h-4 mr-1 ml-1" />{{
                        i18n('common.delete')
                      }}</a
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.admin')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :disabled="!rows.length"
          :isFirstPage="isFirstPage"
          :isLastPage="isLastPage"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="admin" @delete="doDestroyAdmin" />
    <SendNotificationModal
      :type="notificationType"
      :user-ids="selected"
      @close="
        ;(selectedId = null),
          (selectAll = false),
          (selected = []),
          (notificationType = 'custom')
      "
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import SendNotificationModal from '@/components/modals/notification-modal.vue'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import { CustomerPermissions } from './customer-permissions'

export default defineComponent({
  components: {
    DeleteModal,
    // ActivateModal,
    // DisableModal
    SendNotificationModal
  },
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const selectedId = ref('')
    const selectAll = ref(false)
    const notificationType = ref('custom')
    // const sendAll = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        // tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      tabulator,
      selectedId,
      selectAll,
      tableRef,
      notificationType
    }
  },
  data() {
    return {
      filterInput: [],
      selected: ref([]),
      isOpen: ref(false),
      userIds: [],
      columns: [
        {
          name: 'avatar',
          field: 'avatar',
          label: 'iam.fields.avatar',
          align: 'center'
          // required: true,
        },
        {
          name: 'userName',
          field: 'userName',
          label: 'iam.fields.userName',
          align: 'center'
          // required: true,
        },
        {
          name: 'email',
          field: 'email',
          label: 'iam.fields.email',
          align: 'center'
          // required: true,
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'iam.fields.phoneNumber',
          align: 'center'
          // required: true,
        },
        {
          name: 'birthday',
          field: 'birthday',
          label: 'iam.fields.birthday',
          align: 'center'
          // required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'iam.fields.joinDate',
          align: 'center'
          // required: true,
        },
        {
          name: 'status',
          field: 'status',
          label: 'iam.fields.status',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'user/list/rows',
      currentPage: 'user/list/currentPage',
      isFirstPage: 'user/list/isFirstPage',
      isLastPage: 'user/list/isLastPage',
      pagination: 'user/list/pagination',
      listLoading: 'user/list/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    hasPermissionToDestroy() {
      return new CustomerPermissions(this.currentUser).delete
    },
    hasPermissionToChangeStatus() {
      return new CustomerPermissions(this.currentUser).changeStatus
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    statusOptions() {
      return [
        { label: this.i18n('common.activated'), value: 'enabled' },
        { label: this.i18n('common.disabled'), value: 'disabled' }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.filter.fullName'),
          value: 'firstName',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.email'),
          value: 'email',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.phoneNumber'),
          value: 'phoneNumber',
          type: 'text'
        }
      ]
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'user/list/doFetch',
      doFetchNextPage: 'user/list/doFetchNextPage',
      doFetchPreviousPage: 'user/list/doFetchPreviousPage',
      doMountTable: 'user/list/doMountTable',
      doChangeSort: 'user/list/doChangeSort',
      doChangePaginationCurrentPage: 'user/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'user/list/doChangePaginationPageSize',
      doToggleUserAccountStatus: 'user/view/doToggleUserAccountStatus',
      doDestroy: 'user/list/doDestroy',
      doEnableAll: 'user/list/doEnableAllSelected',
      doDisableAll: 'user/list/doDisableAllSelected'
    }),
    async toggleStatus(id, disabled) {
      id = `${id}`
      disabled ? await this.doDisableAll([id]) : await this.doEnableAll([id])
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    select() {
      // this.selected = []
      if (!this.selectAll) {
        this.rows.forEach(element => {
          this.selected.push(element.id)
        })
      } else {
        this.selected = []
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterRole(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(',')
        : 'ـــ'
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter) return
      this.filterInput = filter
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doEnableAllSelected() {
      await this.doEnableAll(this.selected)
      this.clearSelection()
    },
    async doDisableAllSelected() {
      await this.doDisableAll(this.selected)
      this.clearSelection()
    },
    async doDestroyAdmin() {
      await this.doDestroy(this.selectedId)
    },
    async doFetchFirstPage(orderBy = 'createdAt') {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        type: 'customer',
        orderBy,
        filter: this.filterInput,
        pagination: {
          limit: 10,
          sortBy: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'adminTable', 'admins')
    },
    onDownloadPdf() {
      $h.downloadPdf('#adminTable', 'admin', [1, 2, 8])
    },
    onPrint() {
      print({
        printable: 'adminTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 300,
        ignoreElements: [
          'ignore-1',
          'ignore-2',
          'ignore-3',
          'ignore-4',
          'ignore-5',
          'ignore-6'
        ]
      })
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
